<template>
  <div class="custom-height-screen">
    <b-card style="max-width: 640px;margin: 0 auto;width: 100%;min-height: 100px">
      <h1 class="m-0 text-center">
        {{ $t('update-expiration-date') }}
      </h1>
      <div
        class="d-flex justify-content-center mt-3"
        style="gap:20px"
      >
        <b-button
          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
          variant="outline-success"
        >
          {{ $t('Accept') }}
        </b-button>
        <b-button
          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
          variant="outline-danger"
        >
          {{ $t('Reject') }}
        </b-button>
      </div>
    </b-card>
  </div>
</template>
<script>
export default {
  created() {
    this.updateExpirationDate()
  },
  methods: {
    updateExpirationDate() {
      this.axios.get(`/real-estate/${this.$route.params.slug}/update-expiration-date`)
        .then(res => {
          const { data } = res.data
        })
    },
  },
}
</script>
<style>
.custom-height-screen {
  width: 100%;
  height: 80vh;
  min-height: 80vh;
  display: grid;
  place-items: center;
}
</style>
